import React from 'react';
import classNames from 'classnames';
// import { Row, Col } from 'reactstrap';
// import { Fluid, Offset } from 'components/Containers';
import ReactSVG from 'react-svg';
import { withPrefix, navigate } from 'gatsby';
import logger from 'utils/logger';
import PerfectScrollbar from 'react-perfect-scrollbar';
import querystring from 'querystring';

export default class ProductCategories extends React.Component {
  handleQueryString (params, scroll = 0) {
    logger.group(
      __filename,
      '>',
      `handleQueryString (${JSON.stringify(params)})`
    );
    logger.debug('handleQueryString this:', this);
    if (typeof window !== 'undefined') {
      logger.verbose('handleQueryString params:', params);
      const pageQuery = querystring.parse(window.location.search.substring(1));
      logger.verbose('handleQueryString pageQuery:', pageQuery);

      Object.keys(params).forEach((key) => {
        logger.verbose(key, params[key]);
        pageQuery[key] = params[key];
      });

      logger.verbose('handleQueryString pageQuery update:', pageQuery);

      const queryUpdate = `${window.location.pathname.substring(0)}?${querystring.stringify(pageQuery)}`;
      logger.verbose('handleQueryString queryUpdate:', queryUpdate);

      navigate(queryUpdate);
      window.scrollTo({
        top: scroll,
        behavior: 'smooth'
      });
    }
    logger.groupEnd();
  }
  render () {
    const { productsCategories  } = this.props;
    const propClasses = this.props.className;
    const classes = classNames(
      'product-categories',
      'bg-indigo-500',
      'pt-0',
      'pb-20em',
      'text-center',
      'd-none d-lg-block',
      ...propClasses
    );
    logger.debug('ProductsCategories productsCategories', productsCategories)
    const categories = [
      ...productsCategories,
    ]
    logger.debug('ProductsCategories categories', categories)
    return (
      <div>
        <PerfectScrollbar className={`ps-hide-y`}>
          <nav className={classes}>
            <ul className={`productCategoryList pt-20em text-left`}>
              {categories &&
                categories.map(category => {
                  const filterQuery = {}
                  const filterKey = `category`
                  // const filterKey = category.slug === `others` ? `others` : `category`;
                  // if (filterKey === `others`) {
                  //   filterQuery.category = `all`;
                  //   filterQuery[filterKey] = `true`;
                  // } else {
                  //   filterQuery[filterKey] = category.slug;
                  // }
                  filterQuery[filterKey] = category.slug
                  return (
                    <li
                      key={`productCategory-${category.slug}`}
                      className={`pr-18em`}
                    >
                      <button
                        className={`logo button-clear prl-0`}
                        to={`/products?category=${
                          category.slug
                        }`}
                        onClick={e => {
                          this.handleQueryString(
                            filterQuery,
                            scroll
                          )
                        }}
                      >
                        <ReactSVG
                          path={
                            category.icon
                              ? category.icon.file.url
                              : withPrefix(
                                  `/img/icons/apantac-product-icons_${
                                    category.slug
                                  }.svg`
                                )
                          }
                          className={`category ${
                            category.iconStyle
                          } ${category.className}`}
                          svgClassName={category.className}
                          style={{width: "100px"}}
                          wrapperClassName="apantac-icon"
                        />
                        <p
                          className={`mb-0 ff-sans fs-085em`}
                        >
                          {category.name}
                        </p>
                        {/*
                          <p className={`mb-0 tt-uppercase fs-06em ls-100em alpha-50 lh-12`}>
                            {category.description}
                          </p>
                        */}
                      </button>
                    </li>
                  )
                })}
            </ul>
          </nav>
        </PerfectScrollbar>
      </div>
    )
  }
}
