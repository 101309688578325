import React from 'react';
import Layout from '../components/Layout';
import { /*StaticQuery, */graphql, navigate, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import querystring from 'querystring';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';
// import ReactSVG from 'react-svg';
import { StickyContainer, Sticky } from 'react-sticky';

import matchSorter from 'match-sorter';
// const matchSorter = require('match-sorter');

import Fuse from 'fuse.js';

import { Row, Col, NavItem } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
import CardProductSeries from 'components/Cards/CardProductSeries';
import ProductCategories from 'components/Navigation/ProductCategories';
import NavCategories from 'components/Navigation/NavCategories';

// import DataProducts from 'utils/productsStaticData';
// import DataProductLines from 'utils/productLinesStaticData';

class Template extends React.Component {
  handleQueryString (params) {
    logger.group(__filename, '>', `handleQueryString (${JSON.stringify(params)})`);
    logger.debug('handleQueryString this:', this);
    if (typeof window !== 'undefined') {
      logger.verbose('handleQueryString params:', params);
      const pageQuery = querystring.parse(window.location.search.substring(1));
      logger.verbose('handleQueryString pageQuery:', pageQuery);

      Object.keys(params).forEach((key) => {
        logger.verbose(key, params[key]);
        pageQuery[key] = params[key];
      });

      logger.verbose('handleQueryString pageQuery update:', pageQuery);

      const queryUpdate = `${window.location.pathname.substring(0)}?${querystring.stringify(pageQuery)}`;
      logger.verbose('handleQueryString queryUpdate:', queryUpdate);

      navigate(queryUpdate);
    }
    logger.groupEnd();
  }

  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (!data) { return <LoadingView />; }
    let productLinesData = data.allContentfulProductLine.edges;
    // const productGroupsData = data.allProductGroupsJson.edges;
    // const categoriesData = data.allContentfulCategory.edges;

    const fuse = new Fuse(data.allContentfulCategory.edges, {
      keys: ['node.contentTypes']
    });
    // const categoriesData = fuse.search(`productGroups`);
    const productsCategories = data.allContentfulMenu.edges.filter((menu) => {
      logger.debug(`menu`, menu);
      return menu.node.location === `Products Categories`;
    })[0];
    const productsOtherCategories = data.allContentfulMenu.edges.filter((menu) => {
      logger.debug(`menu`, menu);
      return menu.node.location === `Products Other Categories`;
    })[0];
    const productsLinesCategories = data.allContentfulMenu.edges.filter((menu) => {
      logger.debug(`menu`, menu);
      return menu.node.location === `Products Lines`;
    })[0].node.fields.links;
    const categoriesData = productsCategories.node.fields.links;
    logger.debug(`categoriesData`, categoriesData);
    logger.debug(`productsCategories`, productsCategories.node.fields.links);
    logger.debug(`productsOtherCategories`, productsOtherCategories.node.fields.links);
    // let productsData = data.allProductsJson.edges;

    logger.gql('productLinesData:', productLinesData);

    // QueryString Filters
    let pageQuery;
    let filterByCategory;
    if (typeof window !== 'undefined') {
      logger.group(`QueryString Filters`);
      pageQuery = querystring.parse(window.location.search.substring(1));

      Object.keys(pageQuery).forEach((key) => {
        logger.verbose(key);
        if (pageQuery[key] === 'all' || pageQuery[key] === 'ALL' || pageQuery[key] === 'All') {
          pageQuery[key] = null;
        }
      });

      logger.info('QueryString Filters pageQuery:', pageQuery);
      // Filter by lines
      if (pageQuery.line) {
        if (
          pageQuery.line !== 'all' || pageQuery.line !== 'ALL' || pageQuery.line !== 'All'
        ) {
          logger.group(`Filter by lines`);
          logger.info('QueryString Filters pageQuery.line', pageQuery.line);

          productLinesData = productLinesData.filter((productLine) => {
            // logger.debug(productLine.node.slug, pageQuery.line);
            return productLine.node.slug === pageQuery.line;
          });

          logger.info('QueryString Filters productLinesData', productLinesData);
          logger.groupEnd();
        }
      }
      if (pageQuery.category) {
        if (
          pageQuery.category !== 'all' || pageQuery.category !== 'ALL' || pageQuery.category !== 'All' || pageQuery.category !== 'other' || pageQuery.category !== 'others'
        ) {
          logger.group(`Filter by category`);
          logger.info('QueryString Filters pageQuery.category', pageQuery.category);

          filterByCategory = pageQuery.category;

          logger.info('QueryString Filters productLinesData filtered', productLinesData);
          logger.info('matchSorter QueryString Filters productLinesData filtered', productLinesData);

          logger.groupEnd();
        }
      }
      logger.groupEnd();
    }
    let stickyCount = 0;
    const otherCategories = productsOtherCategories.node.fields.links;
    const otherCategoriesSlugs = [];
    otherCategories.forEach(category => {
      if (category.slug !== 'all') {
        otherCategoriesSlugs.push(category.slug);
      }
    });
    const productLineNames = {
      tahoma: `TAHOMA`,
      crescent: `CRESCENT`,
      'mt-hood': `MT HOOD`,
      openGear: `openGear`,
      mazama: `MAZAMA`
    };
    logger.verbose(`categoriesData`, categoriesData);
    logger.verbose(`otherCategories`, otherCategories);
    const currentCategory = categoriesData.find(o => o.slug === filterByCategory);
    let productMatches = false;
    logger.verbose(`currentCategory`, currentCategory);
    logger.templateEnd();

    logger.verbose(`productLinesData`, productLinesData);
    return (
      <Layout>
        <Fluid>
          <SEO title={`Products`} pathname={`/products`} />
          <header>
            <Sticky topOffset={46}>
              {({ style, isSticky }) => (
                <div
                  className={`z-300 transitionSticky ${
                    isSticky ? `isSticky` : `notSticky`
                  }`}
                  style={style}
                >
                  <NavCategories
                    categories={productsLinesCategories}
                    forceKey={`line`}
                    className={`bg-indigo-700`}
                    label={`Product Lines`}
                    allButton={`Show All`}
                  >
                    <NavItem>
                      <a
                        href={`https://squareup.com/market/apantac-llc`}
                        target={`_blank`}
                        className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-indigo-300 color-white-hover`}
                      >
                        Spare Parts
                      </a>
                    </NavItem>
                  </NavCategories>
                  <div className={`${isSticky ? `d-none` : `d-block`}`}>
                    <ProductCategories
                      productsCategories={
                        productsCategories.node.fields.links
                      }
                    />
                  </div>
                  <NavCategories
                    otherCategoriesLabel
                    otherCategories={otherCategories}
                    label={`Categories`}
                    categories={categoriesData}
                    className={`bg-indigo-600`}
                  />
                </div>
              )}
            </Sticky>
          </header>
          <section className={``}>
            <Offset>
              {productLinesData &&
                productLinesData.map(edge => {
                  const productLine = edge.node
                  let productGroups = productLine.product_group || []
                  if (productLine.product_group && filterByCategory) {
                    // const fuse = new Fuse(productGroups, {
                    //   keys: ['categories.slug']
                    // });
                    // productGroups = fuse.search(filterByCategory);

                    productGroups = productGroups.filter(productGroup => {
                      const filter =
                        filterByCategory === 'others'
                          ? JSON.stringify(otherCategoriesSlugs)
                          : filterByCategory
                      let matchTest = []
                      if (
                        productGroup.series &&
                        filterByCategory === 'others'
                      ) {
                        otherCategoriesSlugs.forEach(category => {
                          const matchTestCat = matchSorter(
                            productGroup.series,
                            category,
                            {
                              keys: ['fields.categories'],
                            }
                          )
                          matchTest = [...matchTest, ...matchTestCat]
                        })
                      } else if (productGroup.series && filterByCategory) {
                        matchTest = matchSorter(
                          productGroup.series,
                          filterByCategory,
                          {
                            keys: ['fields.categories'],
                          }
                        )
                      }

                      {
                        /* logger.debug(`matchTest`, filterByCategory, filter, matchTest); */
                      }
                      // logger.debug(`productGroups.filter`, filterByCategory, productGroup);
                      if (productGroup.categories) {
                        return productGroup.categories.some(category => {
                          // logger.debug(`productGroups.filter > category`, category);
                          if (
                            filterByCategory === `other` ||
                            filterByCategory === `others`
                          ) {
                            // logger.verbose(`productGroups.filter > category > otherCategories`, otherCategories, category.slug);
                            if (otherCategories.length > 0) {
                              if (
                                !otherCategories.includes(category.slug)
                              ) {
                                {
                                  /* logger.verbose(`!otherCategories.includes(category.slug)`, !otherCategories.includes(category.slug)); */
                                }
                                return category
                              }
                            } else {
                              return category
                            }
                          }
                          // return category.slug === filterByCategory;
                          return matchTest.length > 0
                        })
                      }
                    })
                  }
                  if (productGroups) {
                    // logger.verbose(`productGroups`, productGroups);
                    productGroups.sort((a, b) => {
                      return b.sort - a.sort
                    })
                    // logger.verbose(`productGroups >>> `, productGroups);
                    // logger.verbose(`productGroups filterByCategory >>> `, filterByCategory);
                  }
                  stickyCount += 1
                  // logger.verbose(`productLine`, productLine);
                  if (productGroups.length > 0) productMatches = true
                  return (
                    productGroups &&
                    (productGroups.length > 0 && (
                      <StickyContainer
                        key={`productLine-${productLine.id}`}
                        className={`container-fluid ptb-10em product-cards ${
                          productLine.group
                        } ${productLine.line}`}
                      >
                        <Row className={`sticky-parent`}>
                          <Sticky topOffset={-88}>
                            {({ isSticky, style }) => (
                              <div
                                className={`pos-relative-soft ${
                                  isSticky
                                    ? `stickyResponsive`
                                    : `notSticky`
                                }`}
                                style={{
                                  ...style,
                                  width: '100%',
                                  zIndex: 1 + stickyCount,
                                  top: isSticky ? `94px` : `0px`,
                                }}
                              >
                                <Col xs={12}>
                                  <h1
                                    className={`bg-white fs-15em ls--30em color-black bb-1 ptb-5em mb-10em`}
                                  >
                                    {productLine.name}{' '}
                                    <span className={`fw-300`}>
                                      {productLine.tagline}
                                    </span>
                                  </h1>
                                </Col>
                              </div>
                            )}
                          </Sticky>
                          <Col xs={12}>
                            {productGroups.map(productGroup => {
                              {
                                /* logger.debug(`productGroups.map((productGroup)`, productGroup); */
                              }
                              let productSeries = []
                              if (productGroup.series && filterByCategory) {
                                if (filterByCategory === 'others') {
                                  otherCategoriesSlugs.forEach(category => {
                                    {
                                      /* logger.debug(`otherCategoriesSlugs.forEach((category)`, category); */
                                    }
                                    const productSeriesConcat = matchSorter(
                                      productGroup.series,
                                      category,
                                      {
                                        keys: ['fields.categories'],
                                      }
                                    )
                                    productSeries = [
                                      ...productSeries,
                                      ...productSeriesConcat,
                                    ]
                                  })
                                  logger.debug(
                                    `filterByCategory === 'others'`,
                                    productSeries
                                  )
                                } else {
                                  productSeries = matchSorter(
                                    productGroup.series,
                                    filterByCategory,
                                    {
                                      keys: ['fields.categories'],
                                    }
                                  )
                                }
                              } else {
                                productSeries = productGroup.series
                              }
                              return (
                                productSeries &&
                                productSeries.length > 0 && (
                                  <Row
                                    key={`productLine-${
                                      productLine.id
                                    }-productGroup-${productGroup.id}`}
                                    className={`ptb-10em product-cards ${
                                      productLine.group
                                    } ${productLine.line}`}
                                  >
                                    <Col xs={12} lg={50}>
                                      <h1
                                        className={`fs-15em lh-12 ls--30em color-black bb-1 pt-5em pb-5em mb-10em`}
                                      >
                                        <span className={`fw-300`}>
                                          {productGroup.name}
                                        </span>
                                      </h1>
                                    </Col>
                                    <Col xs={12} lg={54}>
                                      <Row>
                                        {productSeries &&
                                          productSeries.map(series => {
                                            const cardProps = {
                                              ...series,
                                              productLine:
                                                series.productLine.name,
                                              productGroup:
                                                productGroup.name,
                                              productLineSlug:
                                                series.productLine.slug,
                                              productGroupSlug:
                                                productGroup.slug,
                                            }
                                            logger.debug(`series`, series)
                                            return (
                                              !series.eol && (
                                                <Col
                                                  key={`productLine-${
                                                    productLine.id
                                                  }-productGroup-${
                                                    productGroup.id
                                                  }-series-${series.id}`}
                                                  xl={3}
                                                  md={4}
                                                  sm={6}
                                                >
                                                  <CardProductSeries
                                                    {...cardProps}
                                                  />
                                                </Col>
                                              )
                                            )
                                          })}
                                      </Row>
                                    </Col>
                                  </Row>
                                )
                              )
                            })}
                          </Col>
                        </Row>
                      </StickyContainer>
                    ))
                  )
                })}
              {!productMatches && (
                <div className="ptb-30em">
                  <h1 className={`fs-22em pt-10em pb-15em`}>
                    No products where found matching your selection
                  </h1>
                  <hr />
                  <div className="ptb-20em">
                    {[
                      filterByCategory && (
                        <span className={`fs-11em`}>
                          Show all our{' '}
                          <Link
                            to={`/products/?category=${filterByCategory}`}
                          >
                            {currentCategory.name}
                          </Link>
                        </span>
                      ),
                      filterByCategory && pageQuery.line && (
                        <span
                          className={`fs-095em ptb-10em mrl-5em`}
                          style={{ fontStyle: `italic` }}
                        >
                          {' '}
                          or{' '}
                        </span>
                      ),
                      pageQuery.line && (
                        <span className={`fs-11em`}>
                          {filterByCategory && pageQuery.line ? `s` : `S`}
                          how all{' '}
                          <Link to={`/products/?line=${pageQuery.line}`}>
                            {productLineNames[pageQuery.line]}
                          </Link>{' '}
                          products
                        </span>
                      ),
                    ]}
                  </div>
                </div>
              )}
            </Offset>
          </section>
        </Fluid>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allContentfulProductLine(sort: { fields: [sort], order: DESC } ){
      edges{
        node{
          id
          ... ProductLineBase
          product_group {
            ... ProductGroupBase
            categories {
              ... Category
            }
            series{
              name
              eol
              productLine {
                name
                slug
              }
              fields {
                categories
              }
              ... ProductSeriesBase
              thumbnail {
                ... Thumbnail
              }
              products {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulMenu(filter:{location: {regex: "/Products Categories|Products Other Categories|Products Lines/"}}){
      edges{
         node{
          name
          location
          fields{
            links{
              id
              key
              name
              slug
              contentTypes
              iconStyle
              icon{
                file{
                  url
                }
              }
            }
          }
        }
      }
    }
    allContentfulCategory(
      filter: {
        contentTypes: {
          in: ["productGroups"]
        }
      }
    ){
      edges{
        node{
          ... Category
        }
      }
    }
  }
`;

export default Template;

// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <div>
//         <Template data={data}/>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );
